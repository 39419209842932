/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { EDIT_USER } from '../../graphql/mutations';
import {
  Formik,
  Form
} from 'formik';
import * as Yup from 'yup';
import {
  FormTextField,
  FormButton
} from 'components/reusables/Contents';


const EditUserInfo = ({ user }) => {

  const notifySuccess = () =>
    toast.success('Your information was successfully updated!');

  const notifyError = () =>
    toast.error(
      'Something went wrong, please review your information and try again.'
    );

  const [editUser, { loading }] = useMutation(EDIT_USER, {
    onCompleted: () => notifySuccess(),
    onError: () => notifyError()
  });

  const initValues = () => {
    return {
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      address: user.address || "",
      address_two: user.address || "",
      phone: user.phone || "",
      city: user.city || "",
      state: user.state || "",
      country: user.country || "",
      postal_code: user.postal_code || ""
    };
  };
  return (
    <Formik
      initialValues={initValues()}
      onSubmit={(values, { setSubmitting }) => {
        editUser({
          variables: {
            user_id: user.id,
            modified_by_id: user.id,
            userinput: {
              first_name: values.first_name,
              last_name: values.last_name,
              address: values.address,
              address_two: values.address,
              phone: values.phone,
              city: values.city,
              state: values.state,
              country: values.country,
              postal_code: values.postal_code
            }
          }
        });
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue
        } = props;
        return (
          <>
            <Form onSubmit={handleSubmit} className=" flex flex-col" autoComplete={'nope'}>
              <FormTextField
                margin='dense'
                name='first_name'
                label='First Name'
                value={values.first_name}
                onChange={handleChange}
              />
              <FormTextField
                margin='dense'
                name='last_name'
                label='Last Name'
                value={values.last_name}
                onChange={handleChange}
              />
              <FormTextField
                margin='dense'
                name='phone'
                label='Phone'
                value={values.phone || ''}
                onChange={handleChange}
              />
              <FormTextField
                margin='dense'
                name='address'
                label='Address'
                value={values.address}
                onChange={handleChange}
              />
              <FormTextField
                margin='dense'
                name='city'
                label='City'
                value={values.city}
                onChange={handleChange}
              />
              <FormTextField
                margin='dense'
                name='state'
                label='State'
                value={values.state}
                onChange={handleChange}
              />
              <FormTextField
                margin='dense'
                name='postal_code'
                label='Postal Code'
                value={values.postal_code}
                onChange={handleChange}
              />
              <FormTextField
                margin='dense'
                name='country'
                label='Country'
                value={values.country}
                onChange={handleChange}
              />
              <div className="flex flex-col-cs justify-end ">
              <FormButton color='primary' type='submit' >
                Save Changes
              </FormButton>
              </div>
            </Form>
          </>
        );
      }}
    </Formik >
  );
};

export default EditUserInfo;
