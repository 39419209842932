import React, { useMemo } from 'react';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import DataTable from 'react-data-table-component';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import {
  ContentEmpty,
  DisplayList,
  FormButton
} from '../../reusables/Contents';
import { DataTableStyles } from '../../reusables/styles';
const UsersTable = ({
  users,
  loading,
  handleEditUser,
  count,
  size,
  setSize,
  page,
  setPage,
  sort,
  setSort,
  direction,
  setDirection
}) => {
  console.log('####User table args: ', count, size, page, sort, direction);
  const RowDetail = ({ data }) => (
    <Grid container>
      <Grid item sm={6} xs={12}>
        <DisplayList>
          <ListItem>
            <ListItemAvatar>
              <Avatar src={data.profile_image_url} />
            </ListItemAvatar>
            <ListItemText
              secondary={
                <span className={'flex-row-sc'}>
                  <Typography component={'span'} variant={'h4'}>
                    {data.first_name + ' ' + data.last_name}
                  </Typography>
                  <FormButton
                    edge='0 10px'
                    compact
                    variant={'outlined'}
                    disableElevation
                    onClick={() => handleEditUser(data)}
                  >
                    Edit
                  </FormButton>
                </span>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Username'}
              secondary={
                <>
                  <a target={'_blank'} href={`mailto:${data.username}`}>
                    {data.username}
                  </a>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Role'} secondary={data.role} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Calendar'}
              secondary={data.calendar || ''}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Active'}
              secondary={data.is_active ? 'Yes' : 'No'}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Last Modified'}
              secondary={
                (data.modified_at && moment(data.modified_at).format('lll')) ||
                'N/A'
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Date Joined'}
              secondary={
                data.date_joined && moment(data.date_joined).format('lll')
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Created By'}
              secondary={(data.created_by && data.created_by.username) || 'N/A'}
            />
          </ListItem>
        </DisplayList>
      </Grid>
      <Grid item sm={6} xs={12}>
        <DisplayList>
          <ListItem>
            <ListItemText primary={'Phone'} secondary={data.phone} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Company'} secondary={data.company} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Address'}
              secondary={(data.address || '') + ' ' + (data.address_two || '')}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={'City'} secondary={data.city} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'State'} secondary={data.state} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={'Postal Code'}
              secondary={data.postal_code}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary={'Country'} secondary={data.country} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'ID'} secondary={data.id} />
          </ListItem>
          <ListItem>
            <ListItemText primary={'UID'} secondary={data.uid} />
          </ListItem>
        </DisplayList>
      </Grid>
    </Grid>
  );
  const columns = useMemo(
    () => [
      {
        name: 'NAME',
        selector: 'first_name',
        sortable: true,
        grow: 2,
        cell: row => {
          return <>{`${row.first_name} ${row.last_name}`}</>;
        }
      },
      {
        name: 'USERNAME',
        sortable: true,
        selector: 'username',
        grow: 2,
      },
      {
        name: 'ROLE',
        sortable: true,
        selector: 'role',
        grow: 1
      },
      {
        name: 'PHONE',
        selector: 'phone'
      },
      {
        name: 'JOINED ON',
        selector: 'date_joined',
        sortable: true,
        cell: row => {
          return row.date_joined
            ? `${moment(row.date_joined).format('L')}`
            : '';
        }
      }
    ],
    []
  );
  const handlePageChange = p => {
    console.log('### handlePageChange', page, '--', p);
    setPage(p);
  };
  const handlePerRowsChange = (perPage, page) => {
    console.log('### handleRowsChange', perPage, page);
    setSize(perPage);
    setPage(page);
  };
  const handleSort = (sort, direction) => {
    console.log('### handleSort', sort, direction);
    setSort(sort.selector);
    setDirection(direction);
  };
  return (
    <>
      <div className='data-table'>
        {users.length > 0 && (
          <DataTable
            className='pagination-custom'
            columns={columns}
            data={users}
            noHeader
            expandableRows
            expandableRowsComponent={<RowDetail />}
            expandOnRowClicked
            highlightOnHover
            customStyles={DataTableStyles}
            paginationDefaultPage={page}
            persistTableHead
            paginationPerPage={size}
            pagination
            paginationServer
            paginationTotalRows={count}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            defaultSortField={'date_joined'}
            defaultSortAsc={false}
            onSort={handleSort}
            sortServer={true}
          />
        )}
      </div>
      {!loading && !users.length && (
        <ContentEmpty>
          No user record found. Please adjust your filter.
        </ContentEmpty>
      )}
    </>
  );
};
export default UsersTable;
