import React, { useState, useEffect } from 'react';
import {
  RightDrawer,
  FormTextField,
  FormInputHelperText,
  DrawerCardActions,
  FormButton,
  DrawerCard,
  DrawerCloseButton,
  DrawerCardContent,
} from 'components/reusables/Contents';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardHeader from '@material-ui/core/CardHeader';
import { useParams } from 'react-router-dom';
import {
  Formik,
  Form
} from 'formik';
import * as Yup from 'yup';


const AddUserDrawer = ({ open, setOpen, addTeamMember }) => {
  const { companyId } = useParams();

  console.log("###3 companyId", companyId);
  const handleClose = () => {
    setOpen(false);
  };

  const initValues = () => {
    return {
      first_name: '',
      last_name: '',
      email: '',
      admin: false
    };
  };
  const form = () => {
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
          email: Yup
            .string('Enter email')
            .email('Email must be a valid email address')
            .required('Email is required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          console.log("#### submitting", companyId);
          addTeamMember({
            variables: {
              company_id: companyId,
              email: values.email,
              first_name: values.first_name,
              last_name: values.last_name,
              admin: values.admin
            }
          });
        }}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue
          } = props;
          const handleChecked = value => {
            setFieldValue('admin', value);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete={'nope'}>
              <DrawerCard>
                <CardHeader
                  title='Invite a team member'
                  action={
                    <DrawerCloseButton aria-label='Close' onClick={handleClose} />
                  }
                />
                <DrawerCardContent>
                  <FormTextField
                    required
                    type='email'
                    name='email'
                    label={(touched.email && errors.email) ? errors.email : 'Email'}
                    placeholder='Enter an email'
                    value={form.email}
                    onChange={handleChange}
                  />
                  <FormTextField
                    name='firstName'
                    label='First name (optional)'
                    value={values.firstName}
                    onChange={handleChange}
                  />
                  <FormTextField
                    name='lastName'
                    label='Last name (optional)'
                    value={values.lastName}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='admin'
                        checked={values.admin}
                        onChange={() => handleChecked(!values.admin)}
                      />
                    }
                    label={<h3>Grant team admin access</h3>}
                  />
                  <FormInputHelperText>Allow the member to invite and manage other team members.</FormInputHelperText>
                </DrawerCardContent>
                <DrawerCardActions>
                  <FormButton color='primary' type='submit' size={'medium'}>
                    Submit
                </FormButton>
                  <FormButton onClick={handleClose}>Cancel</FormButton>
                </DrawerCardActions>
              </DrawerCard>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <RightDrawer
      variant='temporary'
      width={400}
      anchor='right'
      open={open}
      onClose={handleClose}
    >
      {form()}
    </RightDrawer>
  );
};

export default AddUserDrawer;
