import React, { useEffect, useState } from 'react';
import { ContentHeader, ContentPaper } from '../../reusables/Contents';
import { toast } from 'react-toastify';
import { useLazyQuery } from '@apollo/react-hooks';
import { SEARCH_USERS } from '../../../graphql/admin';
import UsersAction from './UsersActions';
import UsersTable from './UsersTable';
import useWindowDimensions from '../../reusables/WindowSize';
import { ADMIN, PA } from 'utils/roles';
import Session from "utils/session";
import EditUser from './EditUser';
import {
  FullPage,
  PageHeader,
  PrimaryButton
} from '@tycoonlabs/web-components';
const Users = () => {
  const { width } = useWindowDimensions();
  const role = Session.getRole();
  //For table and filter
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [count, setCount] = useState(0);
  const [size, setSize] = useState(15);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('date_joined');
  const [direction, setDirection] = useState('desc');
  //For editing
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [dialog, setDialog] = useState(false);

  const [fetchUsers, { loading }] = useLazyQuery(SEARCH_USERS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      console.log('### Fetched users', data);
      let page = data.admin_users;
      setFilteredUsers(page.objects);
      setCount(page.count);
      setPage(page.page);
    },
    onError: e => {
      toast.error('Failed to load user.');
    }
  });
  const inputVariables = {
    search: filter,
    size: size,
    page: page,
    sort: sort,
    direction: direction
  };

  useEffect(() => {
    console.log('### fetch users', search, size, page, sort, direction);
    fetchUsers({
      variables: inputVariables
    });
  }, [fetchUsers, filter, size, page, sort, direction]);

  const onSearchChange = e => {
    e.preventDefault();
    console.log('### in onSearchChange', e.target.value);
    setSearch(e.target.value);
  };
  const onSearchPressed = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      console.log('### in onSearchChange', e.target.value);
      setFilter(e.target.value);
    }
  };
  const handleEditUser = user => {
    setSelectedUser(user);
    setOpenDrawer(true);
  };
  const handleNewUser = () => {
    setSelectedUser({});
    setOpenDrawer(true);
  };
  const contentDrawer = () => {
    if (openDrawer) {
      console.log('###Open edit user dialog');
      return (
        <EditUser
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
          selectedUser={selectedUser}
          fetchUsers={fetchUsers}
          inputVariables={inputVariables}
        />
      );
    }
  };
  return (
    <>
      <PageHeader>
        <h1>Users</h1>
        {(role === ADMIN || role === PA) && (
          <PrimaryButton size="medium" color="secondary" onClick={() => handleNewUser('')}>
            New
          </PrimaryButton>
        )}
      </PageHeader>
      <FullPage>
        <UsersAction
          search={search}
          onSearchChange={onSearchChange}
          onSearchPressed={onSearchPressed}
          handleNewUser={handleNewUser}
        />
        <UsersTable
          users={filteredUsers}
          setOpenDrawer={setOpenDrawer}
          handleEditUser={handleEditUser}
          loading={loading}
          count={count}
          size={size}
          setSize={setSize}
          page={page}
          setPage={setPage}
          sort={sort}
          setSort={setSort}
          direction={direction}
          setDirection={setDirection}
        />
      </FullPage>
      {contentDrawer()}
    </>
  );
};
export default Users;
