import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import CardHeader from '@material-ui/core/CardHeader';
import { toast } from 'react-toastify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Session from '../../../utils/session';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import {
  Formik,
  Form,
  useField,
  Field,
  ErrorMessage,
  validateYupSchema
} from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import {
  RightDrawer,
  DrawerCard,
  FormButton,
  FormTextField,
  DrawerCardContent,
  DrawerCardActions,
  DrawerCloseButton,
  FormMenuItem
} from 'components/reusables/Contents';
import { EDIT_USER } from 'graphql/admin';
import { ADMIN, CS, ACCOUNTANT, ASSOCIATE, SALES, CLIENT, PA } from 'utils/roles';
import Nano from '../../../utils/nano';
import useWindowDimensions from '../../reusables/WindowSize';

const UserDrawer = ({
  openDrawer,
  setOpenDrawer,
  selectedUser,
  fetchUsers,
  inputVariables
}) => {
  console.log('### Selected user for edit: ', selectedUser);
  const [roles, setRoles] = useState([CLIENT, SALES, PA]);
  const [showPassword, setShowPassword] = useState(false);

  // const roles = [CLIENT, SALES, PA, ASSOCIATE, ACCOUNTANT, CS, ADMIN];
  const sessionRole = Session.getRole();
  const history = useHistory();
  const { height } = useWindowDimensions();
  const [editUser, { }] = useMutation(EDIT_USER, {
    onCompleted: data => {
      console.log('###USER SAVED', data);
      toast.success('User info saved.');
      setOpenDrawer(false);
      fetchUsers({
        variables: inputVariables
      });
    },
    onError: error => {
      let errors = { error };
      toast.error('Error occurred. ' + errors.error.graphQLErrors[0].message);
      console.log('ERROR OCCURRED WHILE TRYING TO SAVE USER', { error });
    }
  });
  const handleClose = () => {
    setOpenDrawer(false);
  };
  useEffect(() => {
    if (sessionRole === ADMIN) {
      setRoles([CLIENT, SALES, PA, ASSOCIATE, ACCOUNTANT, CS, ADMIN])
    }
  }, []);
  const initValues = () => {
    return {
      id: selectedUser.id,
      first_name: selectedUser.first_name || '',
      last_name: selectedUser.last_name || '',
      password: selectedUser.password || '',
      username: selectedUser.username || '',
      email: selectedUser.email || '',
      calendar: selectedUser.calendar || '',
      phone: selectedUser.phone || '',
      role: selectedUser.role || CLIENT,
      company: selectedUser.company || '',
      address: selectedUser.address || '',
      city: selectedUser.city || '',
      state: selectedUser.state || '',
      postal_code: selectedUser.postal_code || '',
      country: selectedUser.country || '',
      is_active: selectedUser.is_active
    };
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const form = () => {
    console.log('### Setup form with selected user', selectedUser);
    return (
      <Formik
        initialValues={initValues()}
        validationSchema={Yup.object().shape({
          username: Yup
            .string('Enter username')
            .email('Username must be an email')
            .required('User name is required')
        })}
        onSubmit={(values, { setSubmitting }) => {
          editUser({
            variables: {
              input: {
                id: selectedUser.id,
                first_name: values.first_name,
                last_name: values.last_name,
                password: values.password,
                username: values.username,
                calendar: values.calendar,
                phone: values.phone,
                role: values.role || CLIENT,
                company: values.company,
                address: values.address,
                city: values.city,
                state: values.state,
                postal_code: values.postal_code,
                country: values.country,
                is_active: values.is_active
              }
            }
          });
        }}
      >
        {props => {
          const {
            values,
            touched,
            errors,
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue
          } = props;
          const handleChecked = value => {
            setFieldValue('is_active', value);
          };
          return (
            <Form onSubmit={handleSubmit} autoComplete={'nope'}>
              <DrawerCard className={''} style={{ height: `${height}px` }}>
                <CardHeader
                  title={selectedUser.id ? 'Edit User' : 'Add User'}
                  action={
                    <DrawerCloseButton
                      aria-label='Close'
                      onClick={() => handleClose()}
                    />
                  }
                />
                <DrawerCardContent>
                  <FormTextField
                    margin='dense'
                    name='username'
                    label={(touched.username && errors.username) ? errors.username : 'User Name'}
                    placeholder='Enter an email as username'
                    value={values.username}
                    type="email"
                    required
                    InputProps={{
                      readOnly: values.id != null
                    }}
                    error={touched.username && Boolean(errors.username)}
                    onChange={handleChange}
                  />
                  <FormTextField
                    margin='dense'
                    type={showPassword ? 'text' : 'password'}
                    name='password'
                    placeholder='Enter password or leave it blank'
                    label='Password'
                    value={values.password || ''}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{marginBottom: "8px"}}>
                          <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <FormTextField
                    select
                    width={'200px'}
                    name='role'
                    label='Role'
                    value={values.role}
                    onChange={handleChange}
                  >
                    <FormMenuItem value=''>Select Role</FormMenuItem>
                    {roles.map(role => (
                      <FormMenuItem key={role} value={role}>
                        {Nano.caps(role)}
                      </FormMenuItem>
                    ))}
                  </FormTextField>
                  <FormTextField
                    margin='dense'
                    name='first_name'
                    label='First Name'
                    value={values.first_name}
                    onChange={handleChange}
                  />
                  <FormTextField
                    margin='dense'
                    name='last_name'
                    label='Last Name'
                    value={values.last_name}
                    onChange={handleChange}
                  />
                  <FormTextField
                    margin='dense'
                    name='phone'
                    label='Phone'
                    value={values.phone || ''}
                    onChange={handleChange}
                  />
                  <FormTextField
                    margin='dense'
                    name='calendar'
                    label='Calendar'
                    value={values.calendar || ''}
                    onChange={handleChange}
                  />
                  <FormTextField
                    margin='dense'
                    name='address'
                    label='Address'
                    value={values.address}
                    onChange={handleChange}
                  />
                  <FormTextField
                    margin='dense'
                    name='city'
                    label='City'
                    value={values.city}
                    onChange={handleChange}
                  />
                  <FormTextField
                    margin='dense'
                    name='state'
                    label='State'
                    value={values.state}
                    onChange={handleChange}
                  />
                  <FormTextField
                    margin='dense'
                    name='postal_code'
                    label='Postal Code'
                    value={values.postal_code}
                    onChange={handleChange}
                  />
                  <FormTextField
                    margin='dense'
                    name='country'
                    label='Country'
                    value={values.country}
                    onChange={handleChange}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='is_active'
                        checked={values.is_active}
                        onChange={() => handleChecked(!values.is_active)}
                      />
                    }
                    label='Active'
                  />
                </DrawerCardContent>
                <DrawerCardActions>
                  <FormButton color='primary' type='submit' size={'medium'}>
                    Submit
                  </FormButton>
                  <FormButton onClick={handleClose}>Cancel</FormButton>
                </DrawerCardActions>
              </DrawerCard>
            </Form>
          );
        }}
      </Formik>
    );
  };
  return (
    <div>
      <RightDrawer
        variant='temporary'
        width={400}
        anchor='right'
        open={openDrawer}
        onClose={handleClose}
      >
        {form()}
      </RightDrawer>
    </div>
  );
};
export default UserDrawer;
