import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import Avatar from './Avatar';
import Button from '../reusables/Button';
import Spinner from '../reusables/Spinner';
import { EDIT_USER } from '../../graphql/mutations';
import {
  Formik,
  Form
} from 'formik';
import * as Yup from 'yup';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { CLIENT } from 'utils/roles';
import {
  FormTextField,
  FormButton
} from 'components/reusables/Contents';

const ChangeCredentials = ({ user }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const notifySuccess = () =>
    toast.success('Password was successfully updated.');

  const notifyError = () =>
    toast.error(
      'Something went wrong. Please review your information and try again.'
    );

  const [editUser, { loading, error }] = useMutation(EDIT_USER, {
    onCompleted: () => notifySuccess(),
    onError: (error) => notifyError(error)
  });
  const initValues = () => {
    return {
      currentPass: '',
      newPass: '',
      confirmNewPass: ''
    };
  };

  return (
    <Formik
      initialValues={initValues()}
      validationSchema={Yup.object().shape({
        currentPass: Yup
          .string('Enter current password')
          .required('Current password is required'),
        newPass: Yup
          .string('Enter current password')
          .required('Current password is required'),
        confirmNewPass: Yup
          .string('Enter new password confirmation')
          .required('Confirmation for new password is required')
          .test('passwords-match', 'Confirm password must match the new password', function (value) {
            return this.parent.newPass === value
          })
      })}
      onSubmit={(values, { setSubmitting }) => {
        editUser({
          variables: {
            user_id: user.id,
            modified_by_id: user.id,
            userinput: {
              currentPassword: values.currentPass,
              newPassword: values.newPass
            }
          }
        });
      }}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
          setFieldValue
        } = props;
        return (
          <div className="flex flex-col ">
            <div className='avatar-zone  flex flex-col-sc'>
              <Avatar />
              <div className="flex-col-cc" style={{ padding: "18px 0 38px 0" }}>
                <h2>{user.username}</h2>
                {user.role !== CLIENT && <h4 className="flex-row-cc">{user.role}</h4>}
              </div>
            </div>
            <Form onSubmit={handleSubmit} className="" autoComplete={'nope'}>
              <h4 style={{ padding: "18px 0", color: "#999999" }}>Change Password</h4>
              <FormTextField
                margin='dense'
                type={showPassword ? 'text' : 'password'}
                label={(touched.currentPass && errors.currentPass) ? errors.currentPass : 'Current password'}
                error={touched.currentPass && Boolean(errors.currentPass)}
                name='currentPass'
                placeholder='Enter current password'
                value={values.currentPass || ''}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginBottom: "8px" }}>
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormTextField
                margin='dense'
                type={showPassword ? 'text' : 'password'}
                name='newPass'
                placeholder='Enter new password'
                label={(touched.newPass && errors.newPass) ? errors.newPass : 'New password'}
                error={touched.newPass && Boolean(errors.newPass)}
                value={values.newPass || ''}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginBottom: "8px" }}>
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormTextField
                margin='dense'
                type={showPassword ? 'text' : 'password'}
                name='confirmNewPass'
                placeholder='Enter new password to confirm'
                label={(touched.confirmNewPass && errors.confirmNewPass) ? errors.confirmNewPass : 'Confirm new password'}
                error={touched.confirmNewPass && Boolean(errors.confirmNewPass)}
                value={values.confirmNewPass || ''}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginBottom: "8px" }}>
                      <IconButton
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              <FormButton color='primary' type='submit' >
                Change Password
              </FormButton>
            </Form>
          </div>
        );
      }}
    </Formik >
  );
};

export default ChangeCredentials;
