import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { PortalWithState } from 'react-portal';
import { List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Divider } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Session from '../../utils/session';
import { useHistory } from 'react-router-dom';
import { ADMIN, CS, ACCOUNTANT, ASSOCIATE, PA, SALES } from 'utils/roles';
import { ReactComponent as ClientsIcon } from 'assets/clients_black.svg';
import { ReactComponent as UsersIcon } from 'assets/users_black.svg';
import configs from 'config';


const PortalContainer = styled.div`
  position: fixed;
  z-index: 99999;
  top: 90px;
	left: 10px;
  background-color: #fffdfd;
  border: 1px solid #ececec;
  padding: 18px 8px;
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: flex-start;
  justify-content: center;
`;
const NavButton = styled.button`
  background: transparent;
  padding: 0;
  border: 0;
  outline: none;
  cursor: pointer;
`;
const BusinessList = styled(List)`
	&&{
		padding-top: 0;
		padding-bottom: 0;
		width: 100%;
		h2.name{
			color:#212121;
		}
		h3.link{
			color:#212121;
		}
		span.contact{
			color:#424242;
		}
		span.id{
			font-size: 6px;
			margin: 8px 0;
		}
		span{
			display: block;
		}
	}
`
const LogoAvatar = styled(Avatar)`
	  &&{
			&.MuiAvatar-colorDefault{
				color: #FFFFFF;
    		background-color: #424242;
    	}
    	.MuiAvatar-img{
    		object-fit: contain;
    	}
    	&.small{
				width: 24px;
				height: 24px;
    	}
		}
}`;
const Business = (props) => {
	const history = useHistory();
	let current = props.current;
	const role = Session.getRole();
	const user = Session.getUser();
	const jumpTo = (link, closePortal) => {
		history.replace(link)
		closePortal();
	}
	return (
		<PortalWithState closeOnOutsideClick closeOnEsc >
			{({ openPortal, portal, isOpen, closePortal }) => (
				<>
					<NavButton color="primary" onClick={openPortal}>{props.children}</NavButton>
					{portal(
						<PortalContainer>
							{current && current.id && (
								<BusinessList>
									<ListItem disableGutters={true} >
										<ListItemAvatar>
											<LogoAvatar variant="rounded"
												src={current.logo_url}>{current.name.substring(0, 1).toUpperCase()}</LogoAvatar>
										</ListItemAvatar>
										<ListItemText
											primary={<h2 className={"name"}>{current.name && current.name.length > 15 ? current.name.substring(0,15) : current.name}</h2>}
											secondary={
												<>
													<span className={"id"}>{current.id}</span>
													<span className={"contact"}>
														{current && (
															<>
																<span><a href={current.website} target="_blank">{current.website && current.website.length > 25 ? current.website.substring(0,25): current.website}</a></span>
																<span>{current.address}</span>
																<span>{current.city}{current.state && (", " + current.state)} {current.postal_code}</span>
																<span>{current.email}</span>
																<span>{current.phone}</span>
															</>
														)}
													</span>
												</>
											}
										/>
									</ListItem>
								</BusinessList>
							)}
							<>
								<Divider style={{ width: "100%" }} />
								<BusinessList>
									{(role === ADMIN ||
									user.role === PA)
									&& (
										<ListItem disableGutters={true} button onClick={() => jumpTo("/platform/user", closePortal)}>
											<ListItemIcon>
												<UsersIcon />
											</ListItemIcon>
											<ListItemText primary={<h3 className={"link"}>Manage Users</h3>} />
										</ListItem>
									)}
									{(user.role === ADMIN ||
										user.role === CS ||
										user.role === ACCOUNTANT ||
										user.role === ASSOCIATE ||
										user.role === PA ||
										user.role === SALES ||
										user.clients > 1) && (
											<ListItem disableGutters={true} button onClick={() => jumpTo("/clients", closePortal)}>
												<ListItemIcon>
													<ClientsIcon />
												</ListItemIcon>
												<ListItemText primary={<h3 className={"link"}>Manage Clients</h3>} />
											</ListItem>
										)}
								</BusinessList>
							</>
						</PortalContainer>
					)}
				</>
			)}
		</PortalWithState>
	)
}
const BusinessWidget = () => {
	const current = Session.getCurrentBusiness();
	return (
		<>
			<div id="BusinessWidget">
				<Business current={current}>
					<div className={"flex-row justify-between"}>
						<h2 style={{color: configs.styles.companyname || ""}}>{current && (current.name && current.name.length > 15 ? current.name.substring(0, 15) : current.name)}</h2>
						<ExpandMoreIcon  style={{color: configs.styles.companyname || "#FFFFFF"}} fontSize="large"/>
					</div>
				</Business>
			</div>
		</>
	)
}

export { BusinessWidget };