import React, {useState} from "react";
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import {ToastContainer} from 'react-toastify';
import AppBar from '@material-ui/core/AppBar';
import List from "@material-ui/core/List";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import FormHelperText from '@material-ui/core/FormHelperText';

////// LAYOUT COMPONENTS
/**
 * Main content header section.  h3 as content title.
 */
const SubNavBar = styled(AppBar)`
			&&{
				z-index: 99999;
				background: none;
				box-shadow: none;
			}
`;
const SubNavTitle = styled.h2`
			font-weight: 600;
			font-size: 20px;
			font-size: 2rem;
			color:#282828;
`;
const SubNavItem = styled.div`
			font-weight: 600;
      margin: 0 12px;
      line-height: 1em;
      text-transform: uppercase;

      & .active {
        color: #000000;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
      	display: -webkit-box;
				display: -moz-box;
				display: -webkit-flex;
				display: -ms-flexbox;
				display: flex;
				-webkit-flex-flow: column;
				-ms-flex-flow: column;
				flex-flow: column;
        -webkit-box-align: center;
    		-moz-box-align: center;
    		-ms-flex-align: center;
      	align-items: center;
        color: #888888;
        text-decoration: none;
        font-size: 0.7em;

        &:hover {
          color: #282828;
        }
        svg{
      		font-size: 28px;
      	}
      }
`;
const SubNavAction = styled.div`
			border-left: 1px solid #66666650;
      margin-left: 12px;
      .menu-item {
        font-size: 1.2em;
        color: #555555;
    }
`;
const ToastBox = styled(ToastContainer)`
	&&{
		width: 100%;
		top:0;
		padding:0;
		left: 0;
		margin:0;
		text-align: center;
		.Toastify__progress-bar{
			height: 2px;
			top:0;
		}
	.Toastify__toast--default {
    background: #ffffffef;
    color: #212121; 
    text-shadow: 0px 1px #ffffff66;
  }
  .Toastify__toast--info {
    background: #3498dbee; 
    text-shadow: 0px 1px #21212166;
 }
  .Toastify__toast--success {
    background: #07bc0cef;  
    text-shadow: 0px 1px #21212166;
  }
  .Toastify__toast--warning {
    background: #f1c40ff5; 
    text-shadow: 0px 1px #21212166;
  }
  .Toastify__toast--error {
    background: #e74c3cef; 
    text-shadow: 0px 1px #21212166;
  }
}`;
const ContentHeader = styled.div`
			padding: 8px 24px; 
			h3 {
				padding: 12px 0;
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: 600;
				color: #383838;
				margin-bottom:18px;
			}
			h5{
				color: #616161;
				font-weight: 400;
				font-size: 14px;
				font-size: 1.4rem;
			}
			p{
				color: #757575;
				font-weight: 200;
				font-size: 12px;
				font-size: 1.2rem;
			}
`;
/**
 * Main content area without left and right padding.  Ideally use for tables to maximize display area.
 */
const ContentPaper = styled(Paper)`
			&&{
 					width: ${props => props.width ? (props.width - 72) + "px" : "100%"};
					box-shadow: none;
					border:0;
					margin: 0;
					overflow: auto;
				}
`;
/**
 * Main content area with card display.  Ideally for information block display.
 */
const ContentCard = styled(Card)`
			&&{
					box-shadow: none;
					border:1px solid #D4D4D4;
					border-radius: 0;
					margin: 8px 24px;
					.MuiCardHeader-title{
						font-size:1.6rem;
						font-weight:600;
					}
					.MuiCardHeader-subheader{
						font-size:1.2rem;
					}
					display:flex;
					flex-direction: column;
					min-height: 0;
				}
`;
const ContentCardHeader = styled(CardHeader)`
	&& {
		padding-bottom: 8px;
	}
`;
const ContentCardContent = styled(CardContent)`
	&& {
		padding-top: 8px;
	}
`;
const RightDrawer = styled(({width, ...otherProps}) => (
	<Drawer {...otherProps} />
))`
 	&& {
 		.MuiDrawer-paper {
 			width: ${props => `${props.width}px` || "auto"};
 		}
 }
`;

const DrawerCard = styled(Card)`
			&&{
					box-shadow: none;
					border-radius: 0;
					margin: 0px 24px;
					.MuiCardHeader-title{
						font-size:1.6rem;
						font-weight:600;
					}
					display:flex;
					flex-direction: column;
					min-height: 0;
			}
`;
const DrawerCardContent = styled(CardContent)`
			&&{
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
  				overflow:auto;
				}
`;
const DrawerCardActions = styled(CardActions)`
	&&{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 8px 0;
		padding: 8px 0;
	}
`;
const ContentEmpty = styled.div`
			color:#383838;
			text-align: center;
			padding:20px;
			padding:2rem;
			font-size: 16px;
			font-size: 1.6rem;
			background: #EFEFEF;
`;
const DrawerContent = styled.div`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
`;
const DrawerCloseIcon = styled(CloseIcon)`
	&&{
		color: #9E9E9E;
		padding: 0;
		font-size: 3rem;
	}
`;
const DrawerCloseButton = styled(({width, ...otherProps}) => (
	<IconButton {...otherProps}>
		<DrawerCloseIcon/>
	</IconButton>
))``;
const DrawerSecondaryCard = styled(Card)`
			&&{
					box-shadow: none;
					border-radius: 0;
					padding: 24px 0;
					.MuiCardHeader-title{
						font-size:1.4rem;
						font-weight:500;
					}
					background: #EEEEEE;
					transition: width 800ms;
				}
`;
const TextLoader = styled(({ className, children }) => (
	<div className={className}>
		<div id="loading-text">
			<div id="loading-text_1" className="loading-text">L</div>
			<div id="loading-text_2" className="loading-text">o</div>
			<div id="loading-text_3" className="loading-text">a</div>
			<div id="loading-text_4" className="loading-text">d</div>
			<div id="loading-text_5" className="loading-text">i</div>
			<div id="loading-text_6" className="loading-text">n</div>
			<div id="loading-text_7" className="loading-text">g</div>
		</div>
	</div>
))`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-basis: 0;
	flex-grow: 1;
	padding: 50px 0;
`;

const Loader = styled(({ className, children }) => (
	<div className={className}>
		<div id='spinner'/>
	</div>
))`

`;

/**
 * Main content area with card display.  Ideally for information block display.
 */
const ContentCardShadowed = styled(Card)`
			&&{
      		box-shadow: 0 10px 20px rgba(3, 49, 86, 0.2);
					margin: 8px 24px;
				}
`;

const DisplayList = styled(({labelWidth, contentWidth, ...otherProps}) => (
	<List {...otherProps}/>
))`
  	&&{
  		padding:16px 24px;
  		.MuiListItem-root {
  			padding:0;
  		}
  		.MuiListItemAvatar-root{
  			padding:20px 0;
  		}
  		.MuiListItemText-multiline{
  			margin:0;
  			display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				flex-basis: 0;
  			flex-grow: 1;
  			padding:0 8px;
  		}
  		.MuiListItemText-multiline:hover{
  			background:#EEEEEE;
  			cursor: default;
			}  		
  		.MuiListItemText-primary{
  			font-size:14px;
				font-size:1.4rem;
				color:#616161;
				flex-basis: 0;
  			flex-grow: ${props => props.labelWidth || 3};
  		}
  		.MuiListItemText-secondary{
  			font-weight: 600;
				font-size: 1.4px;
				font-size: 1.4rem;
				color:#212121;
				flex-basis: 0;
				max-width: 30rem;
				word-break: break-all;
  			flex-grow: ${props => props.contentWidth || 9};
  		}
  	}
`;
// primaryTypographyProps={{style: {maxWidth:'1rem'}}} secondaryTypographyProps={{style:{maxWidth: '30rem'}}}
////// END LAYOUT COMPONENTS
////// FORM COMPONENTS
/**
 * TextField
 */
const FormTextField = styled(({width, InputProps, ...otherProps}) => (
	<TextField {...otherProps} InputProps={{disableUnderline: true, ...InputProps}} InputLabelProps={{shrink: true}}/>
))`
		width: ${props => props.width || "100%"};
		&&{
			.MuiFormLabel-root {
				font-size:14px;
				font-size:1.4rem;
				color:#282828;
				padding-left: 3px;
			}
			.MuiFormLabel-root.Mui-error{
				color:#f44336;
			}
			.MuiInputBase-root>input[readonly]{
				background:#FFFFFF;
			}
			.MuiInputBase-input{
				background:#F6F6F6;
				border-radius: 5px;
				border:1px solid #FFFFFF;
				font-size: 1.4px;
				font-size: 1.4rem;	
				line-height: 3rem;
				padding: 0 8px;
				height:auto;
				margin: 0 0 8px 0;
			}
			.Mui-focused .MuiInputBase-input {
				background:#FFFFFF;
				border:1px solid #F6F6F6;
			}
			.MuiInputBase-input::placeholder{
				font-size: 12px;
				font-size: 1.2rem;
				font-style: italic;
			}

			.MuiFormHelperText-root{
				font-size: 12px;
				font-size: 1.2rem;
				margin:0;
			}
			.MuiSelect-root.MuiInputBase-input{
				padding: 0 8px;
				margin: 0 0 8px 0;
			}
			.MuiSelect-icon{
				top: calc(50% - 10px);
				right:3px;
			}
		}
`;
const FormInputHelperText = styled(FormHelperText)`
		&&{
			font-size:1.1rem;
		}
`;
const FormButton = styled(({color, compact, ...otherProps}) => (
	<Button {...otherProps} />
))`
	&&{
	${props => (
	props.color === 'primary' ?
		`color: #FFFFFF;
			background:#424242;
			border: 1px solid transparent;
			&:hover {
				color:#212121;
				background:#FFFFFF;
				border: 1px solid #212121;
			}
		` :
		` color: #616161;
			background:none;
			&:hover {
				color:#FFFFFF;
				background:none;
				background: #616161;
			}`)}
	${props => (props.edge ? `margin: ${props.edge};` : ``)}
	${props => (props.fill ? `background: ${props.fill};` : ``)}
			border-radius: 19px; 
			padding:3px 12px 1px 12px;
			${props => (props.compact ? `line-height:1.5;` : ``)}
			${props => (props.compact ? `min-width:0;` : ``)}
			${props => (props.compact ? `padding:2px 9px 1px 9px;` : `padding:3px 12px 1px 12px;`)}
			${props => (props.compact ? `font-size:10px;` : `font-size:11px;`)}
			${props => (props.compact ? `font-size:1rem;` : `font-size:1.1rem;`)}
			.MuiButton-iconSizeSmall > *:first-child {
				font-size: 12px;
				font-size: 1.2rem;
			}
	}
`;
const FormMenuItem = styled(MenuItem)`
	&&{
		font-size: 1.4rem;
	}
`
////// END FORM COMPONENTS
export {
	TextLoader,
	Loader,
	ToastBox,
	SubNavBar,
	SubNavItem,
	SubNavTitle,
	SubNavAction,
	ContentHeader,
	ContentPaper,
	ContentCard,
	ContentCardHeader,
	ContentCardContent,
	ContentCardShadowed,
	RightDrawer,
	DrawerCard,
	DrawerCardContent,
	DrawerCardActions,
	DrawerSecondaryCard,
	DrawerContent,
	DrawerCloseButton,
	ContentEmpty,
	DisplayList,
	FormTextField,
	FormInputHelperText,
	FormButton,
	FormMenuItem
}